import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { shape } from 'prop-types';
import BackgroundImage from 'gatsby-background-image';
import Img from 'gatsby-image';
import React from 'react';

import Layout from '../components/layout';

export default function Work({ data }) {
  return (
    <Layout>
      <article className="bg-light-gray relative">
        <HelmetDatoCms seo={data.datoCmsWork.seoMetaTags} />
        <BackgroundImage
          Tag="section"
          className="h5 bg-gray"
          fluid={data.datoCmsWork.coverImage.fluid}
          style={{
            backgroundPosition: 'top',
            backdropFilter: 'blur(5px)',
          }}
        >
          <div className="absolute bottom-0 w-100 pv4 tc white">
            <h1 className="ma0 f2 f1-ns" style={{ textShadow: '1px 2px 3px #00000091' }}>{data.datoCmsWork.title}</h1>
          </div>
        </BackgroundImage>
        <div className="mw7-ns w-100 center pt4">
          <div
            className="work-details mb3 ph3"
            dangerouslySetInnerHTML={{
              __html: data.datoCmsWork.descriptionNode.childMarkdownRemark.html,
            }}
          />
          <div className="cf pa3">
            <Img fluid={data.datoCmsWork.coverImage.fluid} className="mb3" />
            {data.datoCmsWork.gallery.map(({ fluid }) => (
              <Img key={fluid.src} alt={data.datoCmsWork.title} fluid={fluid} className="mb3" />
            ))}
          </div>
        </div>
      </article>
    </Layout>
  );
}

Work.propTypes = {
  data: shape({}).isRequired,
};

export const query = graphql`
  query WorkQuery($slug: String!) {
    datoCmsWork(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      excerpt
      gallery {
        url
        fluid(maxWidth: 1280, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
      coverImage {
        url
        fluid(maxWidth: 1280, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
    }
  }
`;
